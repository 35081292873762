@media (min-width: 1025px) {
    .layout-wrapper {
        &.layout-overlay {
            .layout-sidebar {
                left: -300px;
            }
            
            .layout-topbar {
                left: 0;
            }
    
            .layout-main, .layout-footer {
                margin-left: 0;
            }
    
            &.layout-overlay-sidebar-active {
                .layout-sidebar {
                    left: 0;
                }
    
                .layout-topbar  {
                    left: 300px;
                }
            }
        }

        &.layout-static {
            .layout-sidebar {
                top: 50px;
            }
            
            .layout-topbar {
                left: 0px;
            }
    
            .layout-main, .layout-footer {
                margin-left: 300px;
            }
    
            &.layout-static-sidebar-inactive {
                .layout-sidebar {
                    left: -300px;
                }
    
                .layout-topbar  {
                    left: 0;
                }

                .layout-main, .layout-footer {
                    margin-left: 0;
                }
            }
        }
    }

    .github-button {
        display: block;
    }
}

.requests-box {
    overflow-y:auto;
    overflow-x:hidden;
    max-height:calc(100% - 210px);
    max-width:100%;
}

@media (max-width: 512px) {
    .desktop-only {
        display: none;
    }
}

@media (max-width: 1024px) {
    .layout-main {
        padding: 16px 16px 16px 16px;
    }

    .requests-box {
        max-height:350px!important;
    }

    .requests-box {
        max-height:200px;
    }

    .layout-wrapper {
        .layout-topbar {
            left: 0;
        }

        .layout-main, .layout-footer {
            margin-left: 0;
        }

        .layout-sidebar {
            position: static;
            padding-top:50px;
            width:100%;
            min-height:400px;
        }

        .layout-mask {
            display: none;
            position: fixed;
            width: 100%;
            height: 100%;
            top: 50px;
            left: 0;
            z-index: 998;
            background-color: $maskBgColor;
            @include opacity(0.7);
        }

        &.layout-mobile-sidebar-active {
            .layout-sidebar {
                left: -0;
            }

            .layout-mask {
                display: block;
            }
        }
    }

    .body-overflow-hidden {
        overflow: hidden;
    }

    .github-button {
        display: none;
    }
}