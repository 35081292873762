* {
    box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: $fontSize;
    color: $textColor;
    font-weight: normal;
    background-color: $bodyBgColor;
    margin: 0;
    padding: 0;
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
}

body.dark {
    background-color: #2f2f2f;
}

a {
    text-decoration: none;
}

.layout-wrapper {
    padding: 0;
    min-height: 100vh;
}

.p-checkbox-label {
    margin-left: 0.5rem;
    line-height: 1.3rem;
}

.p-component * {
    flex-wrap: wrap;
}