:root {
    --sidebar-header-bg-color: #f8f9fa;
    --sidebar-color: #000;
    --sidebar-border-color: #ccc;
    --sidebar-bg-color: #f3f4f9;
}
body.dark {
    --sidebar-header-bg-color: #282828;
    --sidebar-border-color: #000;
    --sidebar-color: #fff;
    --sidebar-bg-color: #151515;
}

.layout-sidebar {
    position: fixed;
    width: 300px;
    height: 100%;
    z-index: 999;
    overflow-y: auto;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    @include transition(left $transitionDuration);
    border-right: 1px solid var(--sidebar-border-color);
    border-bottom: 1px solid var(--sidebar-border-color);

    .layout-logo {
        text-align: center;
        margin-top: 24px;
    }

    .menuitem-badge {
        display: inline-block;
        margin-left: 4px;
        font-size: 10px;
        width: 16px;
        height: 16px;
        line-height: 16px;
        text-align: center;
        color: $menuitemBadgeColor;
        background-color: $menuitemBadgeBgColor;
        @include border-radius(50%);
    }
}

.layout-sidebar-header {
    border-bottom:1px solid var(--sidebar-border-color);
    background-color: var(--sidebar-header-bg-color);
    color:var(--sidebar-color);
    font-size:14px;
}

.layout-sidebar-subheader {
    text-align: center;
    padding: "5px";
    border-bottom: 1px solid var(--sidebar-border-color);
}

.layout-sidebar {
    background-color: var(--sidebar-bg-color);
}